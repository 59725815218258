import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
  selector: 'ui-renta-white-card-details-edit',
  templateUrl: 'white-card-details-edit.component.html',
  styleUrls: ['./white-card-details-edit.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    NgIf
  ]
})
export class WhiteCardDetailsEditComponent {
  @Output() public onEdit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public editable = false;

  @Input() public displayEditButton = true;

  public onEditable = (): void => {
    this.onEdit.emit(true);
  };
}
