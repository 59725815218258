import { Component } from '@angular/core';

@Component({
  selector: 'ui-renta-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: false
})
export class NotFoundComponent {
}
