import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { A11yModule } from '@angular/cdk/a11y';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'ui-renta-lock-warning-dialog',
  templateUrl: './lock-warning-dialog.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    TranslateModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    A11yModule,
    MatIcon,
    MatDialogClose,
    MatIconButton,
    A11yModule,
  ]
})
export class LockWarningDialogComponent {

  constructor(
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<LockWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { userName: string }
  ) {
  }

  public onNoClick(): void {
    this.dialogRef.close({ result: false, data: this.data });
  }

  public onYesClick(): void {
    this.dialogRef.close({ result: true, data: this.data });
  }
}
