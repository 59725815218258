import { Component, Input } from '@angular/core';
import { AdminLeasingCompanyData } from '@shared/models/admin-leasing-company-data';

@Component({
  selector: 'rs-admin-info',
  templateUrl: './rs-admin-info.component.html',
  styleUrls: ['./rs-admin-info.component.scss'],
  standalone: false
})
export class RsAdminInfoComponent {

    @Input() public adminData?: AdminLeasingCompanyData;
}
