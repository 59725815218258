import { Component, computed, OnInit, Signal } from '@angular/core';
import { environment } from '@environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { isMobiSkin, MenuItem } from '@lib/rs-ngx';
import { UserService } from '../../services/user.service';
import { Store } from '@ngrx/store';
import { Title } from '@angular/platform-browser';
import { resetState } from '../../states/core.actions';
import { User } from '../../models/user';
import { CurrentUserGuard } from '@shared/guards/current-user-exists/current-user.guard';
import { toSignal } from '@angular/core/rxjs-interop';
import { FeatureFlagService } from '@core/feature-flag/feature-flag.service';
import { FeatureFlag } from '@core/feature-flag/feature-flag.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit {
  public readonly title = 'MRT';
  public readonly topBarEnvironment: string;
  public readonly menuItems: Signal<MenuItem[]> = this.createMenuItems();
  public readonly _router: Router;
  public topBarCdn: string;
  public readonly appVersion: number;


  public constructor(
    private readonly userService: UserService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly titleService: Title,
    private readonly currentUserGuard: CurrentUserGuard,
  ) {
    this.appVersion = environment.version!;
    this.topBarCdn = environment.topBarCdn;
    this.topBarEnvironment = environment.topBarEnvironment;
    this._router = router;
  }

  public ngOnInit(): void {
    this.router
      .events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentUserGuard.canActivate(event.url);
        }
      });

    this.setAppTitle();
  }

  /** Temp fix to clear state on logout
   * As long as the topbar is managed by roots we have to do this cause they load the entire top bar by script
   */
  public resetStateOnLogout(): void {
    this.userService.setCurrentUser(null);
    this.store.dispatch(resetState());
  }

  public topBarRouteChange($event: string): void {
    if ($event === '/rs-number') {
      this.resetStateOnLogout();
    }
  }

  private createMenuItems(): Signal<MenuItem[]> {
    const user = toSignal(this.userService.getCurrentUser());
    const featureFlags = toSignal(this.featureFlagService.getFeatureFlags(), { initialValue: [] });

    return computed(() => this.initMenus(user(), featureFlags()));
  }

  private initMenus(user: User | null | undefined, featureFlags: FeatureFlag[]): MenuItem[] {
    let menusForUserRole: MenuItem[] = [];
    if (this.userService.isAdminUser()) {
      menusForUserRole = this.initMenusForAdmin(featureFlags);
    }
    if (this.userService.hasAnyLeasingCompanyUserRole()) {
      menusForUserRole = this.initMenusForLeasingCompany(featureFlags);
    }
    if (this.userService.isSupplierUser()) {
      menusForUserRole = this.initMenusForSupplier(featureFlags);
    }
    return [
      ...menusForUserRole,
      {
        url: 'rs-number',
        id: 'rs-number',
        label: 'SWITCH_ACCOUNT',
        display: user !== null,
        labelSuffix: '(' + user?.loginString + ')'
      },
      {
        url: 'old-ui',
        id: 'old-ui',
        display: Boolean(!isMobiSkin && environment.legacyUiUrl && this.shouldDisplayRedirectionToOldUi(featureFlags)),
        label: 'RETURN_TO_OLD_UI',
      },
    ];
  }

  private initMenusForAdmin(featureFlags: FeatureFlag[]): MenuItem[] {
    return featureFlags.includes('new-ui.scope.admin') ?
      [
        {
          label: 'MANAGEMENT',
          id: 'management',
          subMenuItems: [
            {
              id: 'brand-activity-mappings',
              url: 'admin/brand-activity-mappings',
              label: 'BRAND_ACTIVITY_MAPPINGS',
              display: this.userService.isAdminUser()
            },
            {
              url: 'admin/activities/definition',
              id: 'activities',
              label: 'ACTIVITIES',
              display: this.userService.isAdminUser()
            },
            {
              url: 'admin/activity-groups',
              id: 'activityGroups',
              label: 'ACTIVITY_GROUPS',
              display: this.userService.isAdminUser()
            },
            {
              url: 'admin/vehicle-types',
              id: 'admin-vehicle-types',
              label: 'VEHICLE_TYPES',
              display: this.userService.isAdminUser()
            },
            {
              url: 'admin/disapproval-reasons',
              id: 'admin-disapproval-reasons',
              label: 'DISAPPROVAL_REASONS',
              display: this.userService.isAdminUser()
            },
            {
              url: 'admin/rental-classes',
              id: 'rentalClasses',
              label: 'RENTAL_CLASSES',
              display: this.userService.isAdminUser()
            },
            {
              url: 'admin/warranties',
              id: 'warranties',
              label: 'WARRANTIES',
              display: this.userService.isAdminUser()
            },
            {
              url: 'admin/fleet-owners',
              id: 'fleetOwners',
              label: 'FLEET_OWNERS',
              display: this.userService.isAdminUser(),
            },
            {
              id: 'admin-suppliers',
              url: 'admin/suppliers',
              label: 'SUPPLIERS',
              display: this.userService.isAdminUser()
            },
            {
              id: 'tyres',
              url: 'admin/tyres',
              label: 'TYRES',
              display: this.userService.isAdminUser()
            },
            {
              id: 'vehicles',
              url: 'admin/vehicles',
              label: 'VEHICLES',
              display: this.userService.isAdminUser()
            },
            {
              id: 'admin-work-orders',
              url: 'admin/work-orders',
              label: 'WORK_ORDERS',
              display: this.userService.isAdminUser(),
            },
          ],
          display: this.userService.hasAccessToManagement()
        },
        {
          url: 'admin/users',
          label: 'USERS',
          id: 'admin-users',
          subMenuItems: [
            {
              url: 'admin/users',
              label: 'USERS',
              id: 'users',
              display: this.userService.isAdminUser()
            }
          ],
          display: this.userService.isAdminUser()
        },
      ] : [];
  }

  private initMenusForLeasingCompany(featureFlags: FeatureFlag[]): MenuItem[] {
    return featureFlags.includes('new-ui.scope.leasing-company') ? [
      {
        url: 'leasing-company/work-orders',
        id: 'work-orders',
        label: 'WORK_ORDERS',
        display: this.userService.hasAnyLeasingCompanyUserRole() && featureFlags.includes('leasing-company.work-orders')
      },
      {
        label: 'ADMIN',
        id: 'admin',
        subMenuItems: [
          {
            url: 'leasing-company/administration',
            id: 'administration',
            label: 'ADMINISTRATION',
            display: this.userService.hasAnyLeasingCompanyUserRole()
          },
          {
            url: 'leasing-company/user-authorizations',
            id: 'user-authorizations',
            label: 'USER_AUTHORIZATIONS',
            display: this.userService.hasLeasingCompanyManagerUserRole() && featureFlags.includes('leasing-company.user-authorizations')
          },
          {
            url: 'leasing-company/data-mapping',
            id: 'data-mapping',
            label: 'DATA_MAPPING',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() && featureFlags.includes('leasing-company.data-mapping')
          },
          {
            url: 'leasing-company/data-import',
            id: 'data-import',
            label: 'DATA_IMPORT',
            display: this.userService.hasLeasingCompanyManagerUserRole() && featureFlags.includes('leasing-company.data-import')
          }
        ],
        display: this.userService.hasAnyLeasingCompanyUserRole()
      },
      {
        label: 'MANAGEMENT',
        id: 'management',
        subMenuItems: [
          {
            url: 'leasing-company/leasing-contracts',
            id: 'leasing-contracts',
            label: 'LEASING_CONTRACTS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() && featureFlags.includes('leasing-company.leasing-contracts')
          },
          {
            url: 'leasing-company/supplier-groups',
            id: 'supplier-groups',
            label: 'SUPPLIER_GROUPS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() && featureFlags.includes('leasing-company.supplier-groups')
          },
          {
            url: 'leasing-company/suppliers',
            id: 'suppliers',
            label: 'SUPPLIERS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() && featureFlags.includes('leasing-company.suppliers')
          },
          {
            url: 'leasing-company/leasing-company-thresholds',
            id: 'leasing-company-thresholds',
            label: 'LEASING_COMPANY_THRESHOLDS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() && featureFlags.includes('leasing-company.leasing-company-thresholds')
          },
          {
            url: 'leasing-company/maintenance-intervals',
            id: 'main-intervals',
            label: 'MAIN_INTERVALS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() && featureFlags.includes('leasing-company.maintenance-intervals')
          },
          {
            url: 'leasing-company/activity-group-reductions',
            id: 'activity-group-reductions',
            label: 'ACTIVITY_GROUP_REDUCTIONS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() && featureFlags.includes('leasing-company.activity-group-reductions')
          },
          {
            url: 'leasing-company/packs',
            id: 'packs',
            label: 'PACKS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() && featureFlags.includes('leasing-company.packs')
          },
          {
            url: 'leasing-company/custom-comments',
            id: 'customComments',
            label: 'CUSTOM_COMMENTS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole()
          },
          {
            id: 'vehicle-types',
            url: 'leasing-company/vehicle-types',
            label: 'VEHICLE_TYPES',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole(),
          },
          {
            id: 'disapproval-reasons',
            url: 'leasing-company/disapproval-reasons',
            label: 'DISAPPROVAL_REASONS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole(),
          },
          {
            id: 'disapproval-reason-thresholds',
            url: 'leasing-company/disapproval-reason-thresholds',
            label: 'DISAPPROVAL_REASON_THRESHOLDS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole(),
          },
          {
            id: 'preferred-tyre-brands',
            url: 'leasing-company/preferred-tyre-brands',
            label: 'PREFERRED_TYRE_BRANDS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() && featureFlags.includes('leasing-company.preferred-tyre-brands'),
          },
          {
            id: 'rental-classes',
            url: 'leasing-company/rental-classes',
            label: 'RENTAL_CLASSES',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole(),
          },
          {
            id: 'brand-thresholds',
            url: 'leasing-company/brand-thresholds',
            label: 'BRAND_THRESHOLDS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole(),
          },
          {
            id: 'tyre-maintenances',
            url: 'leasing-company/tyre-maintenances',
            label: 'TYRE_MAINTENANCES',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole(),
          },
          {
            id: 'tyre-brand-discounts',
            url: 'leasing-company/tyre-brand-discounts',
            label: 'GLOBAL_TYRE_BRAND_DISCOUNTS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() && featureFlags.includes('leasing-company.tyre-brand-discounts'),
          },
          {
            id: 'leasing-contract-groups',
            url: 'leasing-company/leasing-contract-groups',
            label: 'LEASING_CONTRACT_GROUPS',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole(),
          },
          {
            id: 'cost-centre-configuration',
            url: 'leasing-company/cost-centre-configuration',
            label: 'COST_CENTRES',
            display: this.userService.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole(),
          },
        ],
        display: this.userService.hasAccessToManagement()
      },
    ] : [];
  }

  private initMenusForSupplier(featureFlags: FeatureFlag[]): MenuItem[] {
    return featureFlags.includes('new-ui.scope.supplier') ? [
      {
        url: 'supplier/work-orders',
        id: 'work-orders',
        label: 'WORK_ORDERS',
        display: this.userService.isSupplierUser()
      },
    ] : [];
  }

  private setAppTitle(): void {
    this.titleService.setTitle(isMobiSkin ? 'Mobitech' : 'MRT');
  }

  private shouldDisplayRedirectionToOldUi(featureFlags: FeatureFlag[]): boolean {
    const userType = this.userService.getUserType();
    const displayForAdminScope = featureFlags.includes('new-ui.scope.admin') && userType === 'ADMIN';
    const displayForLeasingCompanyScope = featureFlags.includes('new-ui.scope.leasing-company') && userType === 'LEASING_COMPANY';
    const displayForSupplierScope = featureFlags.includes('new-ui.scope.supplier') && userType === 'SUPPLIER';
    return userType !== null && (displayForAdminScope || displayForLeasingCompanyScope || displayForSupplierScope)
  }
}
