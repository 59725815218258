import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PostalAddressPipe, RentaNgxLibModule, ScrollToModule } from '@lib/rs-ngx';
import { CustomMaterialModule } from './modules/custom-material-module/custom-material-module.module';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { RsMaterialPaginatorIntl } from '@shared/services/material-paginator-intl.service';
import { WhiteCardDetailsEditComponent } from '@shared/components/white-card-details-edit/white-card-details-edit.component';
import { IconTextComponent } from '@shared/components/icon-text/icon-text.component';
import { YearMonthPickerComponent } from '@shared/components/year-month-picker/year-month-picker.component';
import { WarningDialogComponent } from '@shared/components/warning-dialog/warning-dialog.component';
import { ReplaceUnderscorePipe } from '@shared/pipes/replace-underscore.pipe';
import { RsAdminInfoComponent } from '@shared/components/rs-admin-info/rs-admin-info.component';

/** @deprecated Usage will change, please read description below!!!
 *
 * @description What!!! How should we use it now ???
 *
 * - Will be used only to import/export real shared modules like CommonModule, TranslateModule.forChild (Those will be shared only in rentaNgxModule)
 * - For other modules like Material one's, please ONLY import the ones you need where you need them!
 * - For shared components, please create standalone ones and import it only where needed
 * - Same for shared directives, and pipes, please create standalone ones and import it only where needed
 **/
@NgModule({
  imports: [
    CustomMaterialModule,
    MatSidenavModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RentaNgxLibModule,
    ScrollToModule.forRoot(),
    CommonModule,
    PostalAddressPipe,
    WhiteCardDetailsEditComponent,
    IconTextComponent,
  ],
  exports: [
    CustomMaterialModule,
    MatSidenavModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    RentaNgxLibModule,
    ScrollToModule,
    CommonModule,
    WhiteCardDetailsEditComponent,
    IconTextComponent,
    YearMonthPickerComponent,
    WarningDialogComponent,
    ReplaceUnderscorePipe,
    RsAdminInfoComponent
  ],
  declarations: [
    YearMonthPickerComponent,
    WarningDialogComponent,
    ReplaceUnderscorePipe,
    RsAdminInfoComponent
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: RsMaterialPaginatorIntl
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModules {
}
